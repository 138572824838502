import '../scripts/main/collection-carousel'
import { register, SwiperContainer } from 'swiper/element/bundle'
import { SwiperOptions } from 'swiper/types'

document.addEventListener('DOMContentLoaded', function () {
  register()
  const mainBannerSwiperEl = document.querySelector('swiper-container') as SwiperContainer

  if (!mainBannerSwiperEl) {
    return
  }

  const params: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 15000,
    },
    speed: 1500,
    loop: true,
    slidesPerView: 1
  }

  Object.assign(mainBannerSwiperEl, params)

  mainBannerSwiperEl.addEventListener('swiperresize', (event) => {
    const [ swiper ] = (event as CustomEvent).detail;
    if (swiper.width < 768) {
      swiper.navigation.disable();
    } else {
      swiper.navigation.enable();
    }
  });

  mainBannerSwiperEl.initialize();
});
