import { register, SwiperContainer } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';

document.addEventListener('DOMContentLoaded', function () {
  register();
  const swiperEl = document.querySelector('swiper-container.multiple') as SwiperContainer;

  if (!swiperEl) {
    return;
  }
  const nextButton = document.querySelector('.swiper-button-next') as HTMLElement;
  const prevButton = document.querySelector('.swiper-button-prev') as HTMLElement;

  const params: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 3,
    spaceBetween: 42,
    slidesPerGroup: 1,
    loop: true,
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3.5,
      },
    },
  };

  Object.assign(swiperEl, params);
  nextButton.onclick = () => swiperEl.swiper.slideNext();
  prevButton.onclick = () => swiperEl.swiper.slidePrev();

  swiperEl.initialize();
});
